import { EVENT_SIGN_UP } from "constants/events";

declare var window: {
  dataLayer: any[];
  mixpanel: any;
};

interface EventSignUpProps {
  email: string;
}

type EventSignUpType = {
  fireEvent: Function;
};

const useEventSignUp = (): EventSignUpType => {
  return {
    fireEvent: (props: EventSignUpProps, callback?: Function) => {
      if (window) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: EVENT_SIGN_UP });

        if (window.mixpanel) {
          window.mixpanel.track(EVENT_SIGN_UP, props, undefined, callback);
        }
      }
    },
  };
};

export default useEventSignUp;
